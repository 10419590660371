import React, { useEffect } from 'react'
import { NextPage } from 'next'
import dynamic from 'next/dynamic'
import useSWR from 'swr'
import { startOfDay, formatISO } from 'date-fns'
import { PageByUrlQuery, Language, PageCommonQuery, SeoQuery } from 'generated/sdk'
import { BaseLayout } from 'components/layouts/base-layout/base-layout'
import { LoadingPage } from 'components/loading-page/loading-page'
import { ErrorPage, StatusCodes } from 'components/error-page/error-page'
import { BaseSeo } from 'components/base-seo/base-seo'
import { baseLayoutDataMapper } from 'utils/data'
import {
	getUrlPathFromQuery,
	pageByUrlFetcher,
	pageCommonFetcher,
	QueryName,
	seoFetcher,
	removeTrailingSlash,
	getApiUrl,
} from 'utils/url'
import { redirect } from 'utils/router'

const loading = () => <LoadingPage />

const Homepage = dynamic(() => import('components/homepage/homepage'), { loading })
const NewsDetailPage = dynamic(() => import('components/news-detail-page/news-detail-page'), { loading })
const CategoryPage = dynamic(() => import('components/category-page/category-page'), { loading })
const PressReleasePage = dynamic(() => import('components/press-release-page/press-release-page'), {
	loading,
})
const SearchPage = dynamic(() => import('components/search-page/search-page'), { loading })
const EventDetailPage = dynamic(() => import('components/event-detail-page/event-detail-page'), { loading })
const EventsPage = dynamic(() => import('components/events-page/events-page'), { loading })
const StandardPage = dynamic(() => import('components/standard-page/standard-page'), { loading })
const TrafficPage = dynamic(() => import('components/traffic-page/traffic-page'), { loading })

type PageByUrlProps = {
	seoInitialData: SeoQuery | null
	commonInitialData: PageCommonQuery | null
	pageInitialData: PageByUrlQuery | null
	urlPath: string
	language: string
	startDate: string
	searchQuery: string
	page: number
	before: number | null
	statusCode: StatusCodes | null
}

const seoQuery: QueryName = 'Seo'
const commonQuery: QueryName = 'PageCommon'
const pageQuery: QueryName = 'PageByUrl'
const cookiePolicyPages = ['/pt/politica-de-cookies', '/en/cookies-policy']

const PageByUrl: NextPage<PageByUrlProps> = ({
	seoInitialData,
	commonInitialData,
	pageInitialData,
	urlPath,
	language,
	startDate,
	searchQuery,
	page,
	before,
	statusCode,
}) => {
	const { data: seoData, error: seoError } = useSWR([seoQuery, language], seoFetcher, {
		fallbackData: seoInitialData !== null ? seoInitialData : undefined,
	})
	const { data: commonData, error: commonError } = useSWR([commonQuery, language], pageCommonFetcher, {
		fallbackData: commonInitialData !== null ? commonInitialData : undefined,
	})
	const { data: pageData, error: pageError } = useSWR(
		[pageQuery, urlPath, startDate, searchQuery, page, before],
		pageByUrlFetcher,
		{
			fallbackData: pageInitialData !== null ? pageInitialData : undefined,
		}
	)
	const pageUrl = pageData?.pageByUrl?.url || ''

	// This is used to build the cookie table on client side navigation change
	useEffect(() => {
		const isCookiePolicyPage = cookiePolicyPages.some(url => pageUrl.startsWith(url))
		if (isCookiePolicyPage && typeof window !== 'undefined' && typeof Optanon !== 'undefined') {
			setTimeout(() => {
				Optanon.initializeCookiePolicyHtml()
			}, 300)
		}
	}, [pageUrl])

	if (statusCode) {
		return (
			<BaseSeo seoData={seoData?.seo || null} allRss={seoData?.allRss}>
				<ErrorPage statusCode={statusCode} />
			</BaseSeo>
		)
	}

	if (commonError || pageError || seoError) {
		return (
			<BaseSeo seoData={seoData?.seo || null} allRss={seoData?.allRss}>
				<ErrorPage statusCode={500} error={commonError || pageError || seoError} />
			</BaseSeo>
		)
	}

	if (!commonData || !seoData) {
		return (
			<BaseSeo seoData={seoData?.seo || null} allRss={seoData?.allRss}>
				<LoadingPage isFullScreen />
			</BaseSeo>
		)
	}

	const { headerProps, footerProps } = baseLayoutDataMapper(commonData, language)

	let pageComponent: JSX.Element

	if (!pageData) {
		pageComponent = <LoadingPage />
	} else if (pageData.pageByUrl === null || pageData.pageByUrl === undefined) {
		pageComponent = <ErrorPage statusCode={404} isFullScreen={false} />
	} else {
		switch (pageData.pageByUrl.__typename) {
			case 'HomeIndex':
				pageComponent = <Homepage pageData={pageData.pageByUrl} />
				break

			case 'Article':
			case 'Video':
			case 'PhotoGallery':
				pageComponent = <NewsDetailPage pageData={pageData.pageByUrl} />
				break

			case 'ArticlesCategoryIndexPage':
			case 'ArticlesSubCategoryIndexPage':
			case 'ArticlesHighlightsIndexPage':
			case 'ArticlesIndexPage':
				pageComponent = <CategoryPage pageData={pageData.pageByUrl} />
				break

			case 'PhotoGalleriesCategoryIndexPage':
			case 'PhotoGalleriesSubCategoryIndexPage':
			case 'PhotoGalleriesHighlightsIndexPage':
			case 'PhotoGalleriesIndexPage':
				pageComponent = <CategoryPage pageData={pageData.pageByUrl} />
				break

			case 'VideosCategoryIndexPage':
			case 'VideosSubCategoryIndexPage':
			case 'VideosHighlightsIndexPage':
			case 'VideosIndexPage':
				pageComponent = <CategoryPage pageData={pageData.pageByUrl} />
				break

			case 'TopicIndexPage':
				pageComponent = <CategoryPage pageData={pageData.pageByUrl} />
				break

			case 'Event':
				pageComponent = <EventDetailPage pageData={pageData.pageByUrl} />
				break

			case 'EventsIndexPage':
			case 'EventsCategoryIndexPage':
				pageComponent = <EventsPage pageData={pageData.pageByUrl} />
				break

			case 'PressRelease':
				pageComponent = <PressReleasePage pageData={pageData.pageByUrl} />
				break

			case 'SearchIndexPage':
			case 'SearchArticlesIndexPage':
			case 'SearchVideosIndexPage':
			case 'SearchPhotoGalleriesIndexPage':
				pageComponent = <SearchPage pageData={pageData.pageByUrl} />
				break

			case 'StandardPage':
				pageComponent = <StandardPage pageData={pageData.pageByUrl} />
				break

			case 'TrafficPage':
				pageComponent = <TrafficPage pageData={pageData.pageByUrl} />
				break

			default:
				pageComponent = <ErrorPage statusCode={404} isFullScreen={false} />
		}
	}

	return (
		<BaseSeo seoData={seoData.seo} allRss={seoData.allRss}>
			<BaseLayout headerProps={headerProps} footerProps={footerProps}>
				{pageComponent}
			</BaseLayout>
		</BaseSeo>
	)
}

PageByUrl.getInitialProps = async ctx => {
	const { req, query } = ctx
	let language = query.slug![0] as Language
	const startDate = query.startDate
		? (query.startDate as string)
		: formatISO(startOfDay(new Date()), { representation: 'date' })
	const searchQuery = query.searchQuery ? (query.searchQuery as string) : ''
	const page = query.page ? parseInt(query.page as string, 10) : 1
	const before = query.before ? parseInt(query.before as string, 10) : null
	const urlPath = getUrlPathFromQuery(query.slug as string[])
	let seoInitialData: SeoQuery | null = null
	let commonInitialData: PageCommonQuery | null = null
	let pageInitialData: PageByUrlQuery | null = null
	let statusCode: StatusCodes | null = null

	// if slug doesn't start with a valid language
	if (!Object.values(Language).includes(language)) {
		statusCode = 404
		language = Language.Pt
	}
	// if runs on the server, do SSR
	else if (req) {
		const promises = await Promise.all([
			seoFetcher([seoQuery, language], getApiUrl(req)),
			pageCommonFetcher([commonQuery, language], getApiUrl(req)),
			pageByUrlFetcher([pageQuery, urlPath, startDate, searchQuery, page, before], getApiUrl(req)),
		])
		seoInitialData = promises[0]
		commonInitialData = promises[1]
		pageInitialData = promises[2]

		if (pageInitialData.pageByUrl && urlPath !== pageInitialData.pageByUrl?.url) {
			await redirect(ctx, removeTrailingSlash(pageInitialData.pageByUrl?.url), 301)
		}
	}
	// if this runs on the browser return null initial data and use SWR to fetch data
	return {
		seoInitialData,
		commonInitialData,
		pageInitialData,
		urlPath,
		language,
		startDate,
		searchQuery,
		page,
		before,
		statusCode,
	}
}

export default PageByUrl
